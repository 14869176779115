import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutNavbar from "../components/about-navbar"
import { MDXRenderer } from 'gatsby-plugin-mdx'

export const pageQuery = graphql`
  query AboutQuery($id: String!){
	graphCmsPage(id: { eq: $id }) {
		id
		title
		content {
			markdownNode {
				childMdx {
					body
				}
			}
		}
    }
  }
`
const AboutPage = ({ data }) => {
	const { graphCmsPage } = data // data.markdownRemark holds your post data
  const { content, title } = graphCmsPage

	return (
		<Layout className="page">
			<SEO
				title={title}
				description={title}
			/>
			<div className="wrapper">
				<h1>{title}</h1>
				<AboutNavbar/>
				<article>
					<MDXRenderer>{content.markdownNode.childMdx.body}</MDXRenderer>
				</article>
				{/* <article dangerouslySetInnerHTML={{ __html:  content.html}} /> */}
			</div>
		</Layout>
	)
}

export default AboutPage