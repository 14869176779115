/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link,useStaticQuery,graphql } from "gatsby"

const ListLink = (props) => (<li><Link to={props.to}>{props.children}</Link></li>)
const AboutNavbar =  (props)=> {
  const data = useStaticQuery(graphql`
    query AboutNav {
      nav:allGraphCmsAboutNav(sort: {fields: order, order: ASC}) {
        nodes {
          path
          title
        }
      }
    }
  `)
  const MenuItems = data.nav.nodes.map((item,index)=>
    <ListLink key={index} to={item.path}>{item.title}</ListLink>
  )
  return (
    <nav className="about-nav">
      <ul className="grids col-2 sm-2 lg-5">
        {MenuItems}
      </ul>
    </nav>
  )

}

export default AboutNavbar